import React from 'react';
import Button from '@mui/material/Button';

function App() {
  return (
    <div className="App" style={{ padding: 16 }}>
      <header className="App-header">
          <Button variant="contained">Hello World</Button>
      </header>
    </div>
  );
}

export default App;

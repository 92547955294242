import {createTheme, ThemeOptions} from '@mui/material/styles';

// A custom theme for this app
const getTheme = (mode: 'dark' | 'light') => createTheme({
  palette: {
    mode,
    primary: {
      lighter: '#FEE9D1',
      light: '#FDAB76',
      main: '#FA541C',
      dark: '#B3200E',
      darker: '#770508',
    },
    secondary: {
      lighter: '#D2FCF4',
      light: '#77F0ED',
      main: '#22B8CF',
      dark: '#116E95',
      darker: '#063963',
    },
    info: {
      lighter: '#CBFEFC',
      light: '#63E8F9',
      main: '#00B1ED',
      dark: '#0067AA',
      darker: '#003471',
    },
    success: {
      lighter: '#CDFCD1',
      light: '#69F290',
      main: '#0CD66E',
      dark: '#069A6B',
      darker: '#02665B',
    },
    warning: {
      lighter: '#FFF8D1',
      light: '#FFE475',
      main: '#FFC81A',
      dark: '#B7860D',
      darker: '#7A5204',
    },
    error: {
      lighter: '#FFE7D9',
      light: '#FFA48D',
      main: '#FF4842',
      dark: '#B72136',
      darker: '#7A0C2E'
    },
    grey: {
      100: '#F9FAFB',
      200: '#F4F6F8',
      300: '#DFE3E8',
      400: '#C4CDD5',
      500: '#919EAB',
      600: '#637381',
      700: '#454F5B',
      800: '#212B36',
      900: '#161C24',
    },
    common: {
      white: '#FFFFFF',
      black: '#000000',
    },
    text: mode === 'light' ? {
      primary: '#212B36',
      secondary: '#637381',
      disabled: '#919EAB',
    } : {
      primary: '#FFFFFF',
      secondary: '#919EAB',
      disabled: '#637381',
    },
    background: mode === 'light' ? {
      default: '#FFFFFF',
      paper: '#FFFFFF',
      neutral: '#F9FAFB',
    } : {
      default: '#161C24',
      paper: '#212B36',
      neutral: 'rgba(145, 158, 171, 0.12)',
    },
    action: mode === 'light' ? {
      active: '#637381',
      activatedOpacity: 1,
      hover: '#919EAB',
      hoverOpacity: 0.08,
      selected: '#919EAB',
      selectedOpacity: 0.12,
      disabled: '#919EAB',
      disabledOpacity: 0.8,
      disabledBackground: 'rgba(145, 158, 171, 0.24)',
      focus: '#919EAB',
      focusOpacity: 0.24,
    } : {
      active: '#919EAB',
      activatedOpacity: 1,
      hover: '#919EAB',
      hoverOpacity: 0.08,
      selected: '#919EAB',
      selectedOpacity: 0.12,
      disabled: '#919EAB',
      disabledOpacity: 0.8,
      disabledBackground: 'rgba(145, 158, 171, 0.24)',
      focus: '#919EAB',
      focusOpacity: 0.24,
    },
    divider: 'rgba(145, 158, 171, 0.24)',
  },
  typography: {
    fontFamily: [
      'system-ui',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  }
} as ThemeOptions);



export default getTheme;
